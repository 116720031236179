import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import useChartById from "@hooks/api/useChartById";
import { setToastMessage } from "@stores/App/slice";
import { TOAST_TYPES } from "@constants/constants";

const UnderwrittingTabPanel: FC<{
  submoduleId: string;
  selected: string;
  value: string;
  charts: { name: string; id: string; chartType: string; metadata: string }[];
}> = ({ selected, value, charts, submoduleId }) => {
  const dispatch = useDispatch();

  const [isFetched, setIsFetched] = useState(false);
  const [chartDetails, setChartDetails] = useState<string[]>([]);

  // APIs
  // TODO: Uncomment the following hook, and use this for fetching chart data.
  // const { getChartDataById } = useChartById();

  // TODO: Remove the following mock function after API integration
  const getChartDataById = (
    submoduleId: string,
    chartId: string,
    timeout = 1000
  ) => {
    return new Promise<string>((resolve, reject) => {
      setTimeout(() => {
        // reject(new Error(`Failed to fetch data for chartId ${chartId}`));
        resolve(`Data for chartId ${chartId}`);
      }, timeout);
    });
  };

  useEffect(() => {
    if (selected !== value) return;
    if (charts.length === 0) return;

    const filteredChartIds = charts
      .filter((chart) => chart.chartType === value)
      .map((chart) => chart.id);

    fetchChartsDetails(filteredChartIds);
  }, [charts, selected, value]);

  const fetchChartsDetails = async (chartIds: string[]) => {
    if (isFetched) return;
    if (chartIds.length === 0) return;

    try {
      const promises = chartIds.map((chartId) =>
        getChartDataById(submoduleId, chartId)
      );
      const collection = await Promise.all(promises);

      setChartDetails(collection);
      setIsFetched(true);
    } catch (error: any) {
      console.error("Failed fetching charts");
      dispatch(
        setToastMessage({
          type: TOAST_TYPES.ERROR,
          message: "Failed fetching charts",
        })
      );
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={selected !== value}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
    >
      {selected === value && (
        <Box>
          <Stack
            gap={"0.75rem"}
            sx={{
              background: "#fff",
              borderRadius: "0.5rem",
              p: "1rem",
            }}
          >
            <Typography
              fontSize={"1rem"}
              lineHeight={"1.375rem"}
              fontWeight={700}
              color={"#162936"}
            >
              Notes Header
            </Typography>

            <Typography
              fontSize={"0.75rem"}
              lineHeight={"1rem"}
              fontWeight={500}
              color={"#6C6C6C"}
            >
              Our platform analyses key performance metrics such as ROAS,
              retention, and user acquisition efficiency to determine funding
              eligibility. By granting access, we can securely fetch the
              required data and provide an accurate evaluation.
            </Typography>
          </Stack>

          <Stack gap={"1.125rem"} sx={{ mt: "1rem" }}>
            { chartDetails.map((chartDetail, index) => (
              <Stack
                key={index}
                sx={{
                  background: "#fff",
                  borderRadius: "0.5rem",
                  p: "1rem",
                }}
              >
                <Typography
                  fontSize={"1rem"}
                  lineHeight={"1.375rem"}
                  fontWeight={700}
                  color={"#162936"}
                >
                  Chart {index + 1}
                </Typography>
                <Typography
                  fontSize={"0.75rem"}
                  lineHeight={"1rem"}
                  fontWeight={500}
                  color={"#6C6C6C"}
                >
                  {chartDetail}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default UnderwrittingTabPanel;
