// TODO: Remove this file and its references after the BE create the "Underwritting" module in DB
export const underwrittingModuleDetailsResponse = {
    "id": "b2abcabc-2345-6789-0123-456789012345",
    "name": "Underwriting Dashboard",
    "sortOrder": "99",
    "metadata": "{ \"icon\" : \"https://storage.googleapis.com/pvx-core-dev-public/app/icons/underwriting_icon.png\", \"tabs\": [ {\"label\": \"Net ROAS\", \"value\": \"netROAS\" }, {\"label\": \"Retention\", \"value\": \"retention\" }, {\"label\": \"CAC\", \"value\": \"cac\" }, {\"label\": \"Correlation\", \"value\": \"correlation\" }, {\"label\": \"Channels\", \"value\": \"channels\" }, {\"label\": \"Revenue Split\", \"value\": \"revenueSplit\" } ] }",
    "submoduleCatalogs": [
        {
            "id": "2a1cabcd-5090-11ef-b6a4-076984cb005c",
            "name": "Underwriting Dashboard",
            "url": "underwritting-dashboard",
            "embedId": undefined,
            "defaultFilter": undefined,
            "nativeFilterId": undefined,
            "submoduleType": "UNDERWRITTING_DASHBOARD"
        }
    ]
}