import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme
} from "@mui/material"
import {
  ExpandLessRounded,
  ExpandMoreRounded,
  PersonOutlineOutlined
} from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RootState } from "@stores/store";
import { NAV_ROUTES } from "@constants/navRoutes";
import MenuIcon from "@mui/icons-material/Menu";
import { titles } from "@utils/translations/en";
import { SideNavOptions } from "./types";
import { sideNavOptionBorderStyle } from "./common";
import PvXLogoExpanded from "../../assets/icons/pvx-logo-full.svg";
import sideNavBackgroundImage from "../../assets/images/menu-bg.png";
import fallbackIcon from '../../assets/icons/module_fallback_icon.png'
import { SUBMODULE_VIEW } from "@constants/constants";
import UserLogoutPopover from "./components/UserLogoutPopover";


interface MobileNavBarProps {
  filteredOptions: SideNavOptions[];
  handleModuleClick: (index: number, subOptions?: {
    label: string;
    path: string;
  }[]) => void;
  handleSubModuleClick: (path: string, label: string) => void;
  isCurrentRoute: (path: string | string[]) => boolean;
  openModule: number | null;
  setOpenModule: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedSubmodule: React.Dispatch<React.SetStateAction<string | null>>;
  userName: string;
  isAdmin: boolean;
  onClickLogout?: () => void,
  customWidth: string
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({
  filteredOptions,
  handleModuleClick,
  handleSubModuleClick,
  isCurrentRoute,
  openModule,
  setOpenModule,
  setSelectedSubmodule,
  userName,
  isAdmin,
  onClickLogout,
  customWidth
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userList = useSelector((state: RootState) => state?.UserListData);

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const checkIfActive = (option: SideNavOptions): boolean => {
    if (option.label === "Financial Projections") {
			return isCurrentRoute(option.path);
		}

    if (option.subOptions?.length === 1) {
      return isCurrentRoute(option.subOptions.map(sub => sub.path));
    }

    if (!option.subOptions || option.subOptions.length === 0) {
      return isCurrentRoute(option.path);
    }

    return false;
  }

  const handleMobileMenuToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" }, height: "40px" }}>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: theme.zIndex.drawer + 2,
            width: "100%",
            backgroundColor: theme.palette.custom_sidebar.background,
            padding: "16px 24px",
            alignItems: "center",
          }}
        >
          {filteredOptions?.length ? (
            <IconButton
              onClick={handleMobileMenuToggle}
              sx={{
                position: "absolute",
                top: 2,
                left: 2,
                color: theme.palette.common.white,
                p: 1,
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <></>
          )}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              zIndex: theme.zIndex.drawer + 1,
            }}
          >
            <img
              src={PvXLogoExpanded}
              alt={titles.PVX_PARTNERS}
              style={{ width: 120, height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isMobileOpen}
        onClose={handleMobileMenuToggle}
        fullScreen
        sx={{
          position: "absolute",
          top: "40px",
          left: 0,
          width: customWidth,
          maxWidth: isMobileOpen ? "256px" : "336px",
        }}
        PaperProps={{
          style: {
            color: theme.palette.common.white,
            backgroundImage: `url(${sideNavBackgroundImage})`,
            backgroundSize: "cover",
            borderRadius: "1.5rem",
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{ height: "100%", overflowY: "auto", p: 1, pb: 8 }}>
            <List>
              {filteredOptions.map((option, index) => (
                <div key={index}>
                  <ListItem
                    button
                    onClick={() => {
                      handleModuleClick(index, option.subOptions)
                      setIsMobileOpen(false);
                    }}
                    sx={{
                      width: '100%',
                      "&:focus": {
                        backgroundColor: "#2F736E1F",
                      },
                      backgroundColor:
                        checkIfActive(option)
                          ? "#2F736E1F"
                          : "inherit",
                      color: "#F6F8F9",
                      borderRadius: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'flex-start',
                      padding: "16px 12px",
                      my: 1,
                      overflow: "hidden",
                      ...(checkIfActive(option) && {
                        "&::after": sideNavOptionBorderStyle,
                      }),
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: option?.subOptions?.length && option?.subOptions?.length > 1 ? "85%" : "100%",
                        gap: '12px',
                        alignItems: 'center',
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        color: '#F6F8F9'
                      }}
                    >
                      <img src={option.icon as string || fallbackIcon} alt="module-icon" style={{ width: "22px", height: '22px' }} />
                      <Box
                        sx={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.label}
                      </Box>
                    </Box>
                    {Boolean(
                      option.metadata?.submoduleView !== SUBMODULE_VIEW.TAB &&
                      option?.subOptions?.length &&
                      option.subOptions.length > 1
                    ) && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: 24,
                            height: 24,
                            marginRight: 1,
                          }}
                        >
                          {openModule === index ? (
                            <ExpandLessRounded />
                          ) : (
                            <ExpandMoreRounded />
                          )}
                        </Box>
                      )}
                  </ListItem>
                  {
                    option.metadata?.submoduleView !== SUBMODULE_VIEW.TAB &&
                    option.subOptions &&
                    option.subOptions.length > 1 &&
                    openModule === index && (
                      <List
                        component="div"
                        disablePadding
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          ml: 2,
                        }}
                      >
                        {option.subOptions.map((submodule, subIndex) => (
                          <ListItem
                            button
                            key={subIndex}
                            onClick={() => {
                              handleSubModuleClick(
                                `${option.label}/${submodule.path}`,
                                submodule.label
                              );
                              setIsMobileOpen(false);
                            }
                            }
                            sx={{
                              "&:focus": {
                                backgroundColor: "#2F736E1F",
                                color: "#F6F8F9",
                              },
                              backgroundColor: isCurrentRoute([submodule.path])
                                ? "#2F736E1F"
                                : "inherit",
                              color: isCurrentRoute([submodule.path])
                                ? "#F6F8F9"
                                : "#B4B4B4",
                              borderRadius: "24px",
                              display: "flex",
                              alignItems: "center",
                              padding: "8px 16px",
                              width: "90%",
                              my: 1,
                              overflow: "hidden",
                              ...(isCurrentRoute([submodule.path]) && {
                                "&::after": sideNavOptionBorderStyle,
                              }),
                            }}
                          >
                            <ListItemText
                              primary={submodule.label}
                              primaryTypographyProps={{
                                fontWeight: 700,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontFamily: "Manrope, sans-serif",
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                </div>
              ))}
              {isAdmin && (
                <>
                  <ListItem
                    button
                    onClick={() => {
                      navigate(NAV_ROUTES.DASHBOARD_REQUESTS)
                      setOpenModule(null);
                      setSelectedSubmodule(null)
                    }}
                    sx={{
                      "&:focus": { backgroundColor: "#2F736E1F" },
                      backgroundColor: isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS)
                        ? "#2F736E1F"
                        : "trasnparent",
                      color: "#F6F8F9",
                      borderRadius: "24px",
                      display: "flex",
                      alignItems: "center",
                      padding: "12px 16px",
                      overflow: "hidden",
                      ...(isCurrentRoute(NAV_ROUTES.DASHBOARD_REQUESTS) && {
                        "&::after": sideNavOptionBorderStyle,
                      }),
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                      primary="Self Serve Requests"
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigate(NAV_ROUTES.USER_MANAGEMENT)
                      setOpenModule(null);
                      setSelectedSubmodule(null)
                    }}
                    sx={{
                      "&:focus": { backgroundColor: "#2F736E1F" },
                      backgroundColor: isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT)
                        ? "#2F736E1F"
                        : "trasnparent",
                      color: "#F6F8F9",
                      borderRadius: "24px",
                      display: "flex",
                      alignItems: "center",
                      padding: "12px 16px",
                      overflow: "hidden",
                      ...(isCurrentRoute(NAV_ROUTES.USER_MANAGEMENT) && {
                        "&::after": sideNavOptionBorderStyle,
                      }),
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                      primary={titles.USERS}
                    />
                    {userList.allUserCount > 0 && (
                      <ListItemText
                        primary={userList.allUserCount}
                        primaryTypographyProps={{
                          fontSize: "1rem",
                          fontFamily: "Manrope, sans-serif",
                          marginRight: 2,
                          textAlign: "end",
                          fontWeight: 600,
                        }}
                      />
                    )}
                  </ListItem>
                </>
              )}
            </List>
          </Box>
          <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
            {userName && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px 16px",
                    background: "#30434C",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: 24,
                      height: 24,
                      marginRight: 1,
                    }}
                  >
                    <PersonOutlineOutlined />
                  </Box>
                  <ListItemText
                    primary={userName}
                    primaryTypographyProps={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: 600,
                      color: "#F6F8F9",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "140px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: 24,
                      height: 24,
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                      setAnchorEl(event.currentTarget as HTMLElement);
                    }}
                  >
                    <MoreVertIcon />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog >
			{anchorEl && <UserLogoutPopover handlePopoverClose={() => setAnchorEl(null)} logoutHandler={onClickLogout} anchorEl={anchorEl} />}
    </>
  )
}

export default MobileNavBar
