import { useState } from "react";
import { Button, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { selectStyles } from "@utils/styles";
import CohortFilterPill from "./CohortFilterPill";

const FILTER_OPTIONS = [
  { label: "Cohort: 6 Months", value: 6 },
  { label: "Cohort: 12 Months", value: 12 },
  { label: "Cohort: 24 Months", value: 24 },
  { label: "Custom Range", value: "custom" },
];

const CohortFilters = () => {
  const [filter, setFilter] = useState<string | number>(FILTER_OPTIONS[1].value);
  const handleFilterChange = (e: SelectChangeEvent<number | string>) => {
    setFilter(e.target.value);
  };

  return (
    <Stack
      sx={{
        background: "#fff",
        p: "1rem",
        borderRadius: "0.875rem",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{
          pb: "1rem",
          borderBottom: "2px solid #2F736E1F",
        }}
      >
        <Stack direction={"row"} gap={"18px"}>
          <Select
            sx={{
              ...selectStyles,
            }}
            onChange={handleFilterChange}
            value={filter}
          >
            {FILTER_OPTIONS.map((item) => (
              <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
        </Stack>

        <Button
          sx={{
            textTransform: "none",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            fontWeight: 700,
            color: "#51B8B0",
          }}
          startIcon={
            <RestartAltIcon
              sx={{
                color: "#51B8B0",
              }}
            />
          }
        >
          Reset
        </Button>
      </Stack>

      <Stack direction={"row"} gap={"8px"} flexWrap={"wrap"} mt={"1rem"}>
        {Array(18)
          .fill(null)
          .map((item, index) => (
            <CohortFilterPill key={index} />
          ))}
      </Stack>
    </Stack>
  );
};

export default CohortFilters;
