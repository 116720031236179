import { FC, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import CohortFilters from "../components/Underwritting/CohortFilters";
import useCharts from "@hooks/api/useCharts";
import { useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { Module } from "@stores/Modules/types/modules";
import UnderwrittingTabPanel from "../components/Underwritting/UnderwrittingTabPanel";
import { useLazyGetUserModuleGamesQuery } from "@api/userModules";
import { CustomDropdown } from "@components";

interface UnderwrittingTab {
  value: string;
  label: string;
}

interface UnderwrittingChart {
  name: string;
  id: string;
  chartType: string;
  metadata: string;
}

const UnderwrittingDashboard: FC<{ id: string }> = ({ id: submoduleId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const modules = useSelector((state: RootState) => state?.ModulesData);

  const [tabs, setTabs] = useState<UnderwrittingTab[]>([]);
  const [tab, setTab] = useState<string>("");
  const [charts, setCharts] = useState<UnderwrittingChart[]>([]);
  const [gamesList, setGamesList] = useState<any[]>([]);
  const [selectedGame, setSelectedGame] = useState<{
    id: string;
    label: string;
  }>({ id: "", label: "" });

  // APIs
  const [getUserModuleGames] = useLazyGetUserModuleGamesQuery();
  // TODO: Remove the argument after data populated at the BE for underwritting
  const { getChartData } = useCharts(true);

  useEffect(() => {
    fetchGames();
  }, []);

  useEffect(() => {
    if (modules.loadCount === 0) return;

    let underwrittingModule: Module | null = null;
    for (const module of modules.modules) {
      for (const submodule of module.submodules || []) {
        if (submodule.id === submoduleId) {
          underwrittingModule = module;
          break;
        }
      }
    }

    if (!underwrittingModule) return;
    const parsedMetadata = JSON.parse(underwrittingModule.metadata || "{}");

    if (!parsedMetadata.tabs) return;

    // Set tabs
    setTabs(
      parsedMetadata.tabs.map((item: { label: string; value: string }) => ({
        label: item.label,
        value: item.value,
      }))
    );
    // Set default tab
    setTab(parsedMetadata.tabs[0].value);
  }, [modules]);

  useEffect(() => {
    if (!submoduleId) return;

    // Fetch charts based on submoduleId
    fetchCharts(submoduleId);
  }, [submoduleId]);

  const fetchCharts = async (submoduleId: string) => {
    const response = await getChartData(submoduleId);
    setCharts(response || []);
  };

  const fetchGames = async () => {
    const res = await getUserModuleGames({
      submoduleId,
      gamesParam: [],
    });
    if (res.data) {
      const gamesList = res.data.data;
      const sortedGamesList = [...gamesList].sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );

      const selectedGame = sortedGamesList[0];
      setGamesList(sortedGamesList);
      setSelectedGame({
        id: selectedGame?.gameId || "",
        label: selectedGame?.name || "",
      });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Stack p={"2rem"} height={"100vh"} overflow={"scroll"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} gap={"0.5rem"}>
          <Typography
            fontSize={"22px"}
            lineHeight={"30px"}
            fontWeight={700}
            color={"#162936"}
          >
            Underwritting{ gamesList.length > 0 && ":" }
            {gamesList?.length === 1 ? " " + gamesList[0]?.name : ""}
          </Typography>

          {(gamesList?.length || 0) > 1 ? (
            <CustomDropdown
              dropdownOptions={gamesList.map((game) => ({
                id: game.gameId,
                label: game.name,
              }))}
              handleChange={(e) => {
                const currentGame = gamesList.find(
                  (item) => item.gameId === e.target.value
                );
                setSelectedGame({
                  id: currentGame.gameId,
                  label: currentGame.name,
                });
              }}
              selectedOption={selectedGame?.id?.toString() || ""}
              wrapperSx={{
                fontSize: isMobile ? "1rem" : "1.5rem",
                color: "#1B1C17",
                lineHeight: "none",
                border: "1px solid #2F736E46",
              }}
            />
          ) : (
            <></>
          )}
        </Stack>

        <Typography
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={700}
          color={"#6C6C6C"}
        >
          $=USD
        </Typography>
      </Stack>

      <Stack gap={"18px"} mt={"18px"}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="underwritting tabs"
          textColor="primary"
          indicatorColor="primary"
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            backgroundColor: "white",
            borderRadius: "12px 12px 0px 0px",
            borderBottom: "1px solid #F0F0F1",
            ".MuiTabs-indicator": {
              backgroundColor: "#51B8B0",
              height: "2px",
              borderRadius: "5px",
              overflowX: "auto",
            },
            ".MuiTabs-scroller": {
              overflowX: "auto !important",
              scrollbarWidth: "thin",
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={`tab-${tab.value}`}
              icon={<Circle style={{ height: "12px" }} />}
              iconPosition="start"
              label={tab.label}
              value={tab.value}
              sx={{
                paddingY: "0px",
                height: "48px",
                minHeight: "48px",
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 700,
                textTransform: "none",
                color: "#6C6C6C",
                "&.Mui-selected": {
                  color: "#51B8B0",
                },
                alignItems: "center",
              }}
            />
          ))}
        </Tabs>
      </Stack>

      <Box marginTop={"18px"}>
        <CohortFilters />
      </Box>

      <Box mt={"18px"}>
        {tabs.map((item) => (
          <UnderwrittingTabPanel
            submoduleId={submoduleId}
            charts={charts}
            key={`tab-panel-${item.value}`}
            selected={tab}
            value={item.value}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default UnderwrittingDashboard;
