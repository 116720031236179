import { Stack, Typography } from "@mui/material";
import CohortIcon from "./CohortIcon";

const CohortFilterPill = () => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={"4px"}
      sx={{
        borderRadius: "0.5rem",
        boxShadow: "0px 1px 3px 1px #00000026",
        padding: "0.5rem 0.75rem",
        ":hover": {
            cursor: "pointer"
        }
      }}
    >
      <CohortIcon color="red" />
      <Typography
        color={"#6C6C6C"}
        fontSize={"14px"}
        fontWeight={700}
        lineHeight={"20px"}
      >
        2023-01
      </Typography>
    </Stack>
  );
};

export default CohortFilterPill;
