import { FC } from "react";
import { Box, Popover, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { LogoutOutlined } from "@mui/icons-material";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

interface UserLogoutPopoverProps {
  logoutHandler: any;
  anchorEl: null | HTMLElement;
  handlePopoverClose: () => void;
}

interface PopoverItemProps {
  label: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  handler: () => void;
}

const PopoverItem: FC<PopoverItemProps> = ({ label, icon: Icon, handler }) => {
  return (
    <Stack
      direction={"row"}
      gap={"0.5rem"}
      px={"1rem"}
      py={"0.5rem"}
      sx={{
        cursor: "pointer",
        ":hover": {
          backgroundColor: "#E8EAEB",
        },
      }}
      onClick={handler}
    >
      <Icon />
      <Typography fontSize={"1rem"} lineHeight={"1.375rem"} fontWeight={500}>{label}</Typography>
    </Stack>
  );
};

const UserLogoutPopover: FC<UserLogoutPopoverProps> = ({
  anchorEl,
  logoutHandler,
  handlePopoverClose,
}) => {
  const open = Boolean(anchorEl);

  const popoverItems = [
    {
      label: "Feedback",
      icon: RateReviewOutlinedIcon,
      handler: () => {
        handlePopoverClose();
      },
    },
    {
      label: "Continuous Insights",
      icon: CalendarMonthOutlinedIcon,
      handler: () => {
        handlePopoverClose();
      },
    },
    {
      label: "Logout",
      icon: LogoutOutlined,
      handler: logoutHandler,
    },
  ];

  return (
    <Popover
      id={"simple-popover"}
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box width={"175px"} py={"0.5rem"}>
        {popoverItems.map((item, index) => (
          <PopoverItem
            key={index}
            label={item.label}
            icon={item.icon}
            handler={item.handler}
          />
        ))}
      </Box>
    </Popover>
  );
};

export default UserLogoutPopover;
