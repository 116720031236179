// TODO: Remove this file and its dependencies after data populated at the BE for underwritting
export const underwrittingChartsResponse = {
    "code": 1,
    "message": "Success",
    "data": [
        {
            "name": "Net ROAS",
            "id": "netROAS-uuid",
            "metadata": "{\"chartType\":\"netROAS\",\"columns\":[],\"sort\":{}}",
            "chartType": "netROAS"
        },
        {
            "name": "Net ROAS",
            "id": "netROAS-uuid2",
            "metadata": "{\"chartType\":\"netROAS\",\"columns\":[],\"sort\":{}}",
            "chartType": "netROAS"
        },
        {
            "name": "Retention",
            "id": "retention-uuid",
            "metadata": "{\"chartType\":\"retention\",\"columns\":[],\"sort\":{}}",
            "chartType": "retention"
        },
        {
            "name": "CAC",
            "id": "cac-uuid",
            "metadata": "{\"chartType\":\"cac\",\"columns\":[],\"sort\":{}}",
            "chartType": "cac"
        },
        {
            "name": "Correlation",
            "id": "correlation-uuid",
            "metadata": "{\"chartType\":\"correlation\",\"columns\":[],\"sort\":{}}",
            "chartType": "correlation"
        },
        {
            "name": "Channels",
            "id": "channels-uuid",
            "metadata": "{\"chartType\":\"channels\",\"columns\":[],\"sort\":{}}",
            "chartType": "channels"
        },
        {
            "name": "Revenue Split",
            "id": "revenueSplit-uuid",
            "metadata": "{\"chartType\":\"revenueSplit\",\"columns\":[],\"sort\":{}}",
            "chartType": "revenueSplit"
        },
        {
            "name": "Revenue Split",
            "id": "revenueSplit-uuid2",
            "metadata": "{\"chartType\":\"revenueSplit\",\"columns\":[],\"sort\":{}}",
            "chartType": "revenueSplit"
        }
    ]
}