import { setAuthorisedModule, setLoadCount } from '@stores/Modules/slice';
import { NestedUserModulesApiMap, useLazyGetModulesQuery, useLazyGetRequestedSubModulesQuery} from '../../api/userModules';
import { useDispatch } from 'react-redux';
import { Module } from '@stores/Modules/types/modules';
import { convertRequestedSubModulesToModules, updateSubmoduleOrdering } from '@utils/common';
// TODO: Remove the following import after the BE create the "Underwritting" module in DB
import { underwrittingModuleDetailsResponse } from '@hooks/mock/underwritting-module-details.mock';

const useUserModules = () => {
  const dispatch = useDispatch();
  const [getModules, { isLoading, isError }] = useLazyGetModulesQuery();
  const [getRequestedSubModules, { isLoading: isRequestedSubModulesLoading }] = useLazyGetRequestedSubModulesQuery();

  const getUserModules = async (isAdmin?: boolean): Promise<void> => {
    try {
      const actualResponse = await getModules().unwrap();
      // TODO: Remove the following if-block after the BE create the "Underwritting" module in DB
      const response: NestedUserModulesApiMap = JSON.parse(JSON.stringify(actualResponse));
      if (response && response.data && response.data.modules) {
        response.data.modules = [underwrittingModuleDetailsResponse, ...response.data.modules];
      }
      const requestedSubModules = await getRequestedSubModules().unwrap();
  
      let authorizedModules: Module[] = response.data.modules
        .map((module) => ({
          id: module.id,
          name: module.name,
          requestStatus: "APPROVED",
          submodules: module.submoduleCatalogs?.map((submodule) => ({
            id: submodule.id,
            name: submodule.name,
            url: submodule.url,
            embedId: submodule.embedId || '',
            submoduleType: submodule.submoduleType,
            defaultFilter: submodule.defaultFilter || '',
            nativeFilterId: submodule.nativeFilterId || ''
          })) || [],
          metadata: module.metadata,
        }));

      updateSubmoduleOrdering(authorizedModules);
      authorizedModules =  [
        ...authorizedModules,
        ...convertRequestedSubModulesToModules(requestedSubModules.data)
      ];
  
      dispatch(setAuthorisedModule(authorizedModules));
    } catch (error) {
      console.error('Error fetching user modules:', error);
    } finally {
      dispatch(setLoadCount());
    }
  };

  return { getUserModules, isLoading: isLoading || isRequestedSubModulesLoading, isError };
};

export default useUserModules;
