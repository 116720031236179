import { useLazyGetChartsQuery } from "@api/charts";
// TODO: Remove the following import after data populated at the BE for underwritting
import { underwrittingChartsResponse } from "@hooks/mock/underwritting-charts.mock";

const useCharts = (mock: boolean = false) => {
    const [getCharts, { isLoading, isError }] = useLazyGetChartsQuery();

    const getChartData = async (submoduleId: string) => {
        try {
            let response = await getCharts(submoduleId).unwrap();
            // TODO: Remove this file after data populated at the BE for underwritting
            mock && (response = underwrittingChartsResponse);
            if (response?.data) {
                return response.data;
            }

            return null;

        } catch (err) {
            console.error(err);
        }
    };

    return { getChartData, isLoading, isError };
};

export default useCharts;
