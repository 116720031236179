const CohortIcon = ({ color = "#FFC84D" }) => {
    return (
      <svg width="29" height="10" viewBox="0 0 29 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.25" y1="5.67123" x2="20.2546" y2="5.67123" stroke={color} strokeWidth="2.65754"/>
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M23.75 9.82812C26.2353 9.82812 28.25 7.81341 28.25 5.32812C28.25 2.84284 26.2353 0.828125 23.75 0.828125C21.2647 0.828125 19.25 2.84284 19.25 5.32812C19.25 7.81341 21.2647 9.82812 23.75 9.82812ZM23.75 7.82812C25.1307 7.82812 26.25 6.70884 26.25 5.32812C26.25 3.94741 25.1307 2.82812 23.75 2.82812C22.3693 2.82812 21.25 3.94741 21.25 5.32812C21.25 6.70884 22.3693 7.82812 23.75 7.82812Z" 
          fill={color}
        />
      </svg>
    );
  };
  
  export default CohortIcon;
  